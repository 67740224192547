import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

const getSessionDialogs = (state: ReduxState) => state.sessionDialogs

export const getDailyBonusesRequestSelector = createSelector(
  getSessionDialogs,
  (sessionDialogs) => sessionDialogs.dailyBonuses
)
export const getLegalUpdateRequestSelector = createSelector(
  getSessionDialogs,
  (sessionDialogs) => sessionDialogs.legalUpdate
)
export const getOffersRequestSelector = createSelector(getSessionDialogs, (sessionDialogs) => sessionDialogs.offers)
export const getFreeSpinsRequestSelector = createSelector(
  getSessionDialogs,
  (sessionDialogs) => sessionDialogs.freeSpins
)
export const getHistorySessionDialogsSelector = createSelector(
  getSessionDialogs,
  (sessionDialogs) => sessionDialogs.historySessionDialogs
)
export const getSocialMediaRewardRequestSelector = createSelector(
  getSessionDialogs,
  (sessionDialogs) => sessionDialogs.socialMediaReward
)

export const receivedAllRequestsSelector = createSelector(
  getSocialMediaRewardRequestSelector,
  getDailyBonusesRequestSelector,
  getLegalUpdateRequestSelector,
  getOffersRequestSelector,
  getFreeSpinsRequestSelector,
  (socialMediaReward, dailyBonuses, legalUpdate, isOffersRequest, freeSpins) =>
    !dailyBonuses || !legalUpdate || !isOffersRequest || !freeSpins || !socialMediaReward
)
