import { trackOptimizelyEvent } from '@patrianna-payments/analytics/optimizely/events'
import cx from 'classnames'
import type { MouseEvent } from 'react'
import type { SyntheticEvent } from 'react'

import { Link } from 'components/Link'
import { trackEvent } from 'config/analytic'
import { useAppSelector } from 'store/hooks'
import { getUserAccountIdSelector } from 'store/modules/user/selectors'

import { Picture } from '../Picture'
import { ImageType } from '../Picture/Picture.types'

import classes from './styles.module.scss'

type Props = {
  onClick?: (e: SyntheticEvent) => void
  dataTest: string
  src: string
  alt: string
  className?: string
  priority?: boolean
  url?: string
  openInNewTab?: boolean
}

export default function BannerTile(props: Props) {
  const { src, onClick, priority, className, dataTest, alt, url, openInNewTab } = props
  const accountId = useAppSelector(getUserAccountIdSelector)

  const Component = url ? Link : 'div'

  const handleClick = (e: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLDivElement>) => {
    trackEvent('clicked_on_banner', { category: dataTest })
    trackOptimizelyEvent('clickedOnBanner', {
      account_id: accountId,
      bannerName: dataTest,
    })
    onClick?.(e)
  }

  return (
    <Component
      href={url || null}
      target={openInNewTab && '_blank'}
      data-banner
      className={cx(classes.root, className, !!onClick && classes.clickable)}
      onClick={handleClick}
      role='button'
      data-test={dataTest}
    >
      <Picture src={src} alt={alt} loading='lazy' type={ImageType.BANNER} priority={priority} />
    </Component>
  )
}
