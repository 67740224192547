import { Picture } from 'components/Picture'
import { ImageType } from 'components/Picture/Picture.types'

import type { PromoHighlightsProps } from '../..'

import classes from './styles.module.scss'

type Props = {
  promotion: PromoHighlightsProps
}

export default function PromoHighlight(props: Props) {
  const { imageUrl, imageAlt, promoUrl } = props.promotion.data

  return (
    <a href={promoUrl}>
      <div className={classes.imageContainer}>
        <Picture type={ImageType.PROMO_HIGHLIGHTS} className={classes.image} src={imageUrl} alt={imageAlt} />
      </div>
    </a>
  )
}
