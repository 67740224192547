import { useTranslation } from '@patrianna/core-components'
import type { Invitations } from '@patrianna/shared-patrianna-types/store/AccountInvitationsModule'
import { forwardRef } from 'react'
import type { MutableRefObject } from 'react'

import CopyToClipboardInput from 'components/CopyToClipboardInput'
import InvitedFriendInfo from 'components/InvitedFriendInfo'
import InviteFriendInfoSkeleton from 'components/InviteFriendInfoSkeleton'
import { Link } from 'components/Link'
import RichText from 'components/RichText'
import useShareUrl from 'hooks/useShareUrl'
import ROUTES from 'temp/routes.json'

import classes from './SCInviteFriends.module.scss'

type Props = {
  accountId: string
  invitations: Invitations
}

const SCInviteFriends = forwardRef((props: Props, ref: MutableRefObject<HTMLDivElement>) => {
  const t = useTranslation()
  const shareUrl = useShareUrl(props.accountId)

  return (
    <div className={classes.root} data-test='sc-invite-friends-swimlane' ref={ref}>
      <div className={classes.information}>
        <h3>{t('componentReferFriendSwimlane.title')}</h3>
        <RichText
          field={t('componentReferFriendSwimlane.information')}
          components={{
            paragraph: ({ children }) => <p className={classes.infoText}>{children}</p>,
            strong: ({ children }) => <span className={classes.colorSecondary}>{children}</span>,
            em: ({ children }) => <span className={classes.colorGreen}>{children}</span>,
          }}
        />{' '}
        <Link href={ROUTES.REFER_FRIEND}>{t('componentReferFriendSwimlane.link')}</Link>
        {props.invitations && (
          <InvitedFriendInfo
            invitations={props.invitations}
            className={classes.invitedFriendBlock}
            classNameTitle={classes.invitationTitle}
            classNameItem={classes.invitationItem}
          />
        )}
        {!props.invitations && <InviteFriendInfoSkeleton />}
        {/* SWIMLANE */}
        <CopyToClipboardInput
          className={classes.copyClipboardInput}
          buttonsClassName={classes.buttonsContainer}
          shareNative
          inputText={shareUrl}
        />
      </div>

      <div className={classes.imgHolder}>
        <img src={`${process.env.IMG_ORIGIN}/dialogs/invite-friend-section.png?v=3`} alt='dont miss it' />
      </div>
    </div>
  )
})

SCInviteFriends.displayName = 'ReferFriendSwimlane'

export default SCInviteFriends
