import { useMediaQuery } from '@patrianna/shared-hooks'
import cx from 'classnames'

import HorizontalScroll from 'containers/HorizontalScroll'
import JackpotsListItem from 'containers/Jackpots/components/JackpotListItem'
import useSyncJackpotTotals from 'hooks/useSyncJackpotTotals'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getGoldJackpotTotalsSelector,
  getSweepstakeJackpotTotalsSelector,
  getWinsJackpotSelector,
} from 'store/modules/jackpots/selectors'

import classes from './styles.module.scss'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

type Props = {
  hideFooter?: boolean
  smallImages?: boolean
  scEnabled?: boolean
}

export default function Jackpots(props: Props) {
  const goldJackpotTotals = useAppSelector(getGoldJackpotTotalsSelector)
  const sweepStakeJackpotTotals = useAppSelector(getSweepstakeJackpotTotalsSelector)
  const isMobile = useMediaQuery('lg')
  const scEnabled = useAppSelector(sweepstakeEnabledSelector)
  const jackpotWins = useAppSelector(getWinsJackpotSelector)

  useSyncJackpotTotals()

  if (!isJackpotEnabled) {
    return null
  }

  return (
    <>
      {isMobile && (
        <HorizontalScroll classNameContainer={cx(classes.root, { [classes.hideFooter]: props.hideFooter })}>
          {scEnabled && (
            <JackpotsListItem
              hideFooter={props.hideFooter}
              smallImages={props.smallImages}
              jackpotTotal={sweepStakeJackpotTotals}
              jackpotWins={jackpotWins.SC}
              sc
            />
          )}
          <JackpotsListItem
            hideFooter={props.hideFooter}
            smallImages={props.smallImages}
            jackpotTotal={goldJackpotTotals}
            jackpotWins={jackpotWins.GC}
          />
        </HorizontalScroll>
      )}
      {!isMobile && (
        <div className={cx(classes.withoutScroll, { [classes.hideFooter]: props.hideFooter })}>
          {scEnabled && (
            <JackpotsListItem
              hideFooter={props.hideFooter}
              smallImages={props.smallImages}
              jackpotTotal={sweepStakeJackpotTotals}
              jackpotWins={jackpotWins.SC}
              sc
            />
          )}
          <JackpotsListItem
            hideFooter={props.hideFooter}
            smallImages={props.smallImages}
            jackpotTotal={goldJackpotTotals}
            jackpotWins={jackpotWins.GC}
          />
        </div>
      )}
    </>
  )
}
