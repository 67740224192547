import { builder } from '@builder.io/react'

import BannerTile from 'components/BannerTile'
import useBuilderCMSData from 'hooks/useBuilderCMSData'

import { generateBannerUrl } from '../utils/generateBannerUrl'

builder.init(process.env.BUILDER_KEY)

const PROMOTION_BANNERS_FROM_BUILDER = process.env.CMS_DATA_MODEL_PROMOTIONS_HOMEPAGE_BANNERS

export type BannerProps = {
  data: {
    url: string
    src: string
    alt: string
    dataTest: string
  }
}

export default function PromoBannersFromBuilder() {
  const promoBanners = useBuilderCMSData(PROMOTION_BANNERS_FROM_BUILDER)

  return (
    <>
      {promoBanners.map((el: BannerProps, index) => {
        const {
          data: { src, url, alt, dataTest },
        } = el
        const bannerUrl = generateBannerUrl(src)

        return <BannerTile key={index} url={url} src={bannerUrl} alt={alt} dataTest={dataTest} />
      })}
    </>
  )
}
