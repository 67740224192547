'use client'

import { Fade } from '@patrianna/core-components'
import type { Provider } from '@patrianna/shared-patrianna-types/store/ProviderModule'

import { useAuth } from 'app/context/auth'
import CategoryDescription from 'components/CategoryDescription'
import { Show } from 'components/Show'
import Categories from 'containers/Categories'
import GameProviders from 'containers/GameProviders'
import Highlights from 'containers/Highlights'
import HomeBanners from 'containers/HomeBanners'
import BigRegisterBanner from 'containers/HomeBanners/banners/BigRegisterBanner'
import Jackpots from 'containers/Jackpots'
import ReferFriendSwimlane from 'containers/ReferFriendSwimlane'
import WelcomeMessage from 'containers/WelcomeMessage'
import useIsLandscape from 'hooks/useIsLandscape'
import useProviderJackpots from 'hooks/useProviderJackpots'
import useRedirectFromQueryParam from 'hooks/useRedirectFromQueryParam'
import { getNumberOfPreloadedItems } from 'utils/games'

import SlotsRow from '../SlotsRow'

import ProductGrid from './components/ProductGrid'
import classes from './HomeContent.module.scss'
import type { HomeContentProps } from './HomeContent.types'
import { useCategorySelection } from './hooks/useCategorySelection'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

export default function HomeContent(props: HomeContentProps) {
  const { categories, isMobileUserAgent, cookies, footerCategoryDescription } = props
  const { isLoggedIn } = useAuth()
  const isLandscape = useIsLandscape()
  const { selectedCategoryData, isItemSelected, onClickFilter } = useCategorySelection({
    categories,
  })

  useProviderJackpots()
  useRedirectFromQueryParam()

  return (
    <div className={classes.pageContent}>
      <WelcomeMessage />
      <Show when={isLoggedIn} fallback={<BigRegisterBanner isMobile={isMobileUserAgent} />}>
        <HomeBanners cookies={cookies} isMobileUserAgent={isMobileUserAgent} />
      </Show>
      <Categories
        alignCategoriesOnClick
        productLayoutCategories={categories}
        controllers={{
          onCategoryClick: onClickFilter,
          categoryPredicate: isItemSelected,
        }}
      />
      <Fade in={!selectedCategoryData} className={classes.firstSwimLane}>
        {/* This blank div serves to improve the INP metric when clicking on categories */}
        <div hidden={!!selectedCategoryData}>
          {categories.map((category) => {
            if (category.type === 'providers') {
              return (
                <GameProviders
                  title={category.title}
                  key='category-providers'
                  providers={category.products as unknown as Provider[]}
                  className={classes.title}
                  isMobileUserAgent={isMobileUserAgent}
                />
              )
            }

            if (category.type === 'jackpot' && isJackpotEnabled) {
              return <Jackpots key='category-jackpot' />
            }

            if (category.title === 'Refer a Friend') {
              // це тимчасово, поки бекенд не додасть type: 'refer-friend' (бекенд таска 1730)
              return <ReferFriendSwimlane key='refer-a-friend' />
            }

            if (category.title === 'Highlights') {
              // це тимчасово, поки бекенд не додасть type: 'highlights' (бекенд таска 1815)
              return <Highlights title={category.title} key='highlights' />
            }

            const numberOfPreLoadedItems = getNumberOfPreloadedItems(category, isMobileUserAgent, isLandscape)

            return (
              <SlotsRow
                games={category.products}
                title={category.title}
                iconImg={category.icon}
                count={category.products.length}
                lines={category.lines}
                type={category.type}
                style={category.style}
                url={`${category.route}`}
                promotionUrl={category.promotionUrl}
                isMobileUserAgent={isMobileUserAgent}
                key={`category-${category.index}`}
                numberOfPreLoadedItems={numberOfPreLoadedItems}
                className={classes.title}
                categoryCode={category.code}
              />
            )
          })}
        </div>
      </Fade>
      <Fade key={selectedCategoryData?.code} in={true}>
        {selectedCategoryData && <ProductGrid selectedCategoryData={selectedCategoryData} />}
      </Fade>
      {footerCategoryDescription && <CategoryDescription content={footerCategoryDescription} isFooterContent />}
    </div>
  )
}
