import { useTranslation } from '@patrianna/core-components'
import { usePageViewTracker } from '@patrianna/shared-hooks'
import { captureMessage } from '@sentry/nextjs'
import cx from 'classnames'
import { useState } from 'react'

import Button from 'components/Button'
import { trackEvent } from 'config/analytic'
import useShareApiAvailability from 'hooks/useShareApiAvailability'

import classes from './styles.module.scss'

type TSharingNativeMobile = {
  title?: string
  text?: string
  customEvent?: string
  inJackpot?: boolean
  url?: string
  className?: string
}

export const SharingNativeMobile = ({ url, title, text, customEvent, inJackpot, className }: TSharingNativeMobile) => {
  const t = useTranslation()
  const [sharingIsActive, setSharingIsActive] = useState(false)
  const { isShareAPIAvailable } = useShareApiAvailability()

  usePageViewTracker(() => {
    if (inJackpot && isShareAPIAvailable) {
      trackEvent('jackpotwin_share_show')
    }
  })

  if (!isShareAPIAvailable) {
    return null
  }

  const handleClick = async (): Promise<void> => {
    setSharingIsActive(true)
    trackEvent(customEvent || 'clicked_on_referfriend_sharelink')
    const shareData = {
      ...(title && { title }),
      ...(text && { text }),
      ...(url && { url }),
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      if (!err.toString().includes('AbortError')) {
        captureMessage(`Error in SharingNativeMobile: ${err?.message}; Agent: ${navigator?.userAgent}`)
      }
    } finally {
      setSharingIsActive(false)
    }
  }

  return (
    <Button
      disabled={sharingIsActive}
      onClick={handleClick}
      variant='contained'
      size='small'
      className={cx(classes.shareButton, className)}
    >
      <img src={`${process.env.IMG_ORIGIN}/dialogs/share.png`} alt='Share' />
      {t('common.share_link')}
    </Button>
  )
}
