import { usePathname } from 'next/navigation'
import { createRef } from 'react'

import { DROP_SELECTED_CATEGORY } from 'config/customEvents'
import useDomEvent from 'hooks/useDomEvent'

import type { PillRef, UseAutoScrollContract } from '../Categories.types'
import cacheAutoScroll from '../service/CacheAutoScroll'
import { getHomePillRef } from '../utils/getHomePillRef'
import { getScrollPredicate } from '../utils/getScrollPredicate'

/**
 * A custom hook for auto-scrolling to the selected category.
 *
 * @hook useAutoScroll
 * @param {Array<Category>} categories - An array of category objects.
 * @param {boolean} alignCategoriesOnClick - Specifies if categories should align on click.
 * @param {Function} predicate - A predicate function that verifies if the current route is the same as the category route.
 *
 * @returns {{
 *   getPillRef: (index: number) => PillRef;
 *   autoScrollCategories: (container: HTMLDivElement) => void;
 * }} An object that contains the following methods and properties:
 *   - `getPillRef` - A function that returns the pill reference.
 *   - `autoScrollCategories` - A function that scrolls to the selected category.
 *
 * This hook leverages the usePathname hook from Next.js to handle routing and navigation among categories. It maintains
 * a reference to each category "pill" (represented as a React ref) for easy access to each category element.
 *
 * A custom DOM event `CUSTOM_EVENT.DROP_SELECTED_CATEGORY` is used to trigger the auto-scrolling behavior. This event
 * can be dispatched from any part of the application and will result in automatic scrolling to the home category.
 * This event-based approach provides a loosely coupled way of triggering the auto-scrolling functionality from various
 * points in the application without requiring direct access to the `useAutoScroll` hook instant.
 *
 * If `alignCategoriesOnClick` is true, auto-scroll behavior is triggered whenever the custom event is dispatched,
 * causing the viewport to smoothly scroll to the 'home' category position.
 *
 * The `autoScrollCategories` function iterates over all category refs and uses the custom predicate function to
 * determine the correct category to scroll to. The viewport will smoothly scroll to this determined category.
 */

export const useAutoScroll: UseAutoScrollContract = (categories, alignCategoriesOnClick, predicate) => {
  const pathname = usePathname()
  const pillRefs: PillRef[] = categories.map(() => createRef())
  const currentPredicateFn = getScrollPredicate({ categories, pathname, predicate })

  useDomEvent(DROP_SELECTED_CATEGORY, () => {
    if (alignCategoriesOnClick) {
      const homePillRef = getHomePillRef(pillRefs)
      const parent = homePillRef?.parentElement
      const scrollToItemOffset = homePillRef?.offsetLeft

      if (parent && scrollToItemOffset != null) {
        const leftPadding = 2
        parent.scrollTo({ top: 0, left: scrollToItemOffset - leftPadding, behavior: 'smooth' })
      }
    }
  }, [pillRefs])

  return {
    getPillRef: (index) => pillRefs[index],
    // FYI: cachedAutoScrollCategories is server for deciding when should scroll to the selected category
    autoScrollCategories: cacheAutoScroll.autoScrollCategories(currentPredicateFn, pillRefs, pathname),
  }
}
