import type { MutableRefObject } from 'react'

export const scrollToPill = (
  pillRef?: MutableRefObject<HTMLDivElement>,
  scrollRef?: MutableRefObject<HTMLDivElement>
) => {
  if (pillRef?.current && scrollRef?.current) {
    const pillOffsetLeft = pillRef.current.offsetLeft
    const leftPadding = 2
    scrollRef.current.scrollTo({
      top: 0,
      left: pillOffsetLeft - leftPadding,
      behavior: 'smooth',
    })
  }
}
