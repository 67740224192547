import type { CategoryCode } from '@patrianna/shared-patrianna-types/services/productCategories'
import { nextTick } from '@patrianna/shared-utils'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'

import { CATEGORY_QUERY_PARAM } from 'constants/queryParams'

export const useCategoryState = () => {
  const searchParams = useSearchParams()
  const [selectedCategoryCode, setSelectedCategoryCode] = useState<CategoryCode | null>(null)

  const replaceQueryParams = (code?: CategoryCode) => {
    const newUrl = new URL(window.location.href)

    if (code) {
      newUrl.searchParams.set(CATEGORY_QUERY_PARAM, code)
    } else {
      newUrl.searchParams.delete(CATEGORY_QUERY_PARAM)
    }

    window.history.pushState({ path: newUrl.toString() }, '', newUrl.toString())
  }

  const fallbackSetSelectedCategoryCode = (code?: CategoryCode) => {
    setSelectedCategoryCode(code || null)
  }

  const toggleCategory = (code?: CategoryCode) =>
    nextTick(() => {
      if (window && window.history.pushState) {
        return replaceQueryParams(code)
      }

      return fallbackSetSelectedCategoryCode(code)
    })

  return {
    selectedCategoryCode: selectedCategoryCode || searchParams.get(CATEGORY_QUERY_PARAM),
    toggleCategory,
  }
}
