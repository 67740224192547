import { useSearchParams, useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { useAuth } from 'app/context/auth'

const useRedirectFromQueryParam = () => {
  const searchParams = useSearchParams()
  const { isLoggedIn } = useAuth()
  const router = useRouter()

  const from = searchParams.get('from')

  useEffect(() => {
    let onlyOneRedirect = true

    if (searchParams.has('from') && isLoggedIn && onlyOneRedirect) {
      router.push(from)
    }

    return () => {
      onlyOneRedirect = false
    }

    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isLoggedIn])
}

export default useRedirectFromQueryParam
