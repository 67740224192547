import { getApiBanners } from '@patrianna/shared-store/banners/store/actions'
import {
  getBannersPriorityLessFifty,
  getBannersPriorityMoreFifty,
  getTargetBannersLoaded,
} from '@patrianna/shared-store/banners/store/selectors'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { useEffect } from 'react'

import { IS_USER_OPENED_SC_RULES } from 'app/constants/cookies'
import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import BannerCollection from 'containers/BannerCollection'
import TargetBanners from 'containers/HomeBanners/banners/TargetBanners'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import EXTERNAL_LINKS from 'temp/externalLinks.json'

import DailyReward from './banners/DailyReward'
import LoyaltyProgramBanner from './banners/LoyaltyProgramBanner'
// import Offers from './banners/Offers'
import PromoBannersFromBuilder from './banners/PromoBannersFromBuilder'
import RegisterBanner from './banners/RegisterBanner'
import SCbanner from './banners/SCbanner'
import SocialBanner from './banners/SocialBanner'
import WelcomeEmail from './banners/WelcomeEmail'
import classes from './styles.module.scss'

type Props = {
  cookies: string
  isMobileUserAgent: boolean
}

export default function HomeBanners(props: Props) {
  const { isLoggedIn, isScEnabled, isWelcomeEmailSubmited } = useAuth()
  const isSCBannerClicked = typeof document !== 'undefined' && getCookie(IS_USER_OPENED_SC_RULES, document.cookie)
  const showSCbanner = isScEnabled && !isSCBannerClicked
  const { bannersFeature, dailyRewardFeature } = useCountryConfig()
  const targetBannersMorePriority = useAppSelector(getBannersPriorityMoreFifty)
  const targetBannersLessPriority = useAppSelector(getBannersPriorityLessFifty)
  const targetBannersLoaded = useAppSelector(getTargetBannersLoaded)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (bannersFeature.enabled && isLoggedIn && !targetBannersLoaded) {
      // @ts-ignore TODO
      dispatch(getApiBanners())
    }
  }, [bannersFeature.enabled, isLoggedIn, targetBannersLoaded])

  return (
    <BannerCollection
      enableBackdrop={false}
      autoScrollTimeInterval={5000}
      showArrows={!props.isMobileUserAgent}
      showPagination={props.isMobileUserAgent}
      scrollClassName={classes.homeCarousel}
    >
      {bannersFeature.enabled && (
        <>
          <TargetBanners banners={targetBannersMorePriority} />
          {/*<Offers />*/}
          <TargetBanners banners={targetBannersLessPriority} />
          {isLoggedIn && process.env.LOYALTY_PROGRAM_ENABLED && <LoyaltyProgramBanner />}
          {!isLoggedIn && <RegisterBanner />}
          {!isLoggedIn && dailyRewardFeature.enabled && <DailyReward />}
          {!isScEnabled && <SocialBanner imgKey='facebook' link={EXTERNAL_LINKS.visitFB} />}
          {!isScEnabled && <SocialBanner imgKey='instagram' link={EXTERNAL_LINKS.instagram} />}
          {showSCbanner && <SCbanner />}
          {isScEnabled && !isWelcomeEmailSubmited && <WelcomeEmail />}
          <PromoBannersFromBuilder />
        </>
      )}

      {!bannersFeature.enabled && (
        <>
          {!isLoggedIn && <RegisterBanner />}
          {dailyRewardFeature.enabled && <DailyReward />}
          {showSCbanner && <SCbanner />}
          {isLoggedIn && <PromoBannersFromBuilder />}
        </>
      )}
    </BannerCollection>
  )
}
