import type { TypedThunk } from 'store'

import { actions } from './slice'
import { getHabaneroJackpots } from './utils'

export const { saveHabaneroJackpots } = actions

export const getHabaneroJackpotsAction =
  (currency: 'SC' | 'GC'): TypedThunk =>
  async (dispatch) => {
    await getHabaneroJackpots(currency).then((jackpots) => {
      dispatch(saveHabaneroJackpots({ jackpots }))
    })
  }
