import { useTranslation } from '@patrianna/core-components'
import { useMediaQuery } from '@patrianna/shared-hooks'
import cx from 'classnames'
import type { ReactElement } from 'react'

import Button from 'components/Button'
import { Link } from 'components/Link'
import TransitionNumber from 'components/TransitionNumber'
import { trackEvent } from 'config/analytic'
import WinBadge from 'containers/Jackpots/components/WinBadge'
import { useInView } from 'hooks/useInView'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import { getSweepstakeJackpotOptInSelector, isAccountJackpotInfoLoading } from 'store/modules/jackpots/selectors'
import type { JackpotTotals, JackpotTotalsCode } from 'store/types/JackpotsModule'
import ROUTES from 'temp/routes.json'

import LastWin from '../LastWin'

import classes from './styles.module.scss'

type Props = {
  sc?: boolean
  jackpotTotal: JackpotTotals[]
  jackpotWins?: {
    [type in JackpotTotalsCode]?: number
  }
  hideFooter?: boolean
  smallImages?: boolean
}

const CELL_HEIGHT = 34
const MOBILE_CELL_HEIGHT = 24

export default function JackpotsListItem(props: Props): ReactElement | null {
  const { ref } = useInView({
    threshold: 1,
    triggerOnce: true,
    onChange: (inView) => handleOptButtonInView(inView),
  })
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('sm')
  const { sc, jackpotTotal } = props
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const t = useTranslation()
  const isOptedIn = useAppSelector(getSweepstakeJackpotOptInSelector)
  const accountJackpotInfoLoading = useAppSelector(isAccountJackpotInfoLoading)
  const JPDataTest = sc ? 'sc-jackpot-block' : 'gc-jackpot-block'

  const handleOptInClick = () => {
    trackEvent('optintojackpots_swimlane_optin_click')
    dispatch(openDialog({ modalName: 'JACKPOTS_SWEEPSTAKE_INFO_DIALOG' }))
  }

  const handleOptOutClick = () => {
    trackEvent('optintojackpots_swimlane_optout_click')
    dispatch(openDialog({ modalName: 'JACKPOTS_SWEEPSTAKE_INFO_DIALOG' }))
  }
  const handleOptButtonInView = (inView: boolean) => {
    if (inView) {
      trackEvent(isOptedIn ? 'optintojackpots_swimlane_optout_shown' : 'optintojackpots_swimlane_optin_shown')
    }
  }

  return (
    <div
      className={cx(classes.listItem, {
        [classes.sc]: sc,
        [classes.gc]: !sc,
        [classes.smallImage]: props.smallImages,
        [classes.hideFooter]: props.hideFooter,
      })}
      data-test={`${sc ? 'sc' : 'gc'}-jackpot-block`}
    >
      <div className={cx(classes.title, { [classes.titleSc]: sc })}>
        {sc ? t('common.sweepstakesCoins') : t('common.goldCoins')}
      </div>
      <img
        src={`${process.env.IMG_ORIGIN}/gameInfo_${sc ? 'sc' : 'gc'}.png`}
        alt='reward'
        className={cx(classes.currencyImage, { [classes.smallImage]: props.smallImages })}
        width={'160'}
        height={'40'}
      />
      <ul className={classes.innerList}>
        {jackpotTotal?.map((item, index) => {
          const winBadgeCount = props.jackpotWins?.[item?.code]

          return (
            <li
              className={cx(classes.ilItem, classes[item.code], {
                [classes.withBadge]: Number.isInteger(winBadgeCount),
              })}
              key={index}
              data-test={`jp-item-${sc ? 'sc' : 'gc'}-${item.code}`}
            >
              <div className={classes.ilInfoWrapper}>
                <div className={classes.ilTitle}>
                  {sc ? t(`jackpots_home_banners.sc_${item.code}`) : t(`jackpots_home_banners.gc_${item.code}`)}
                </div>
                {item.lastWinAt && <LastWin lastWinAt={item.lastWinAt} />}
                <TransitionNumber
                  classNames={cx(classes.ilQnt, { [classes.smallImage]: props.smallImages })}
                  height={isMobile ? MOBILE_CELL_HEIGHT : CELL_HEIGHT}
                  jackpotRowInfo={item}
                  sc={sc}
                  dataTest={`jackpot-${item.code}-prize`}
                />
                <div className={classes.ilCaption}>
                  {sc ? t('jackpots_home_banners.sweepstake_coins') : t('jackpots_home_banners.gold_coins')}
                </div>
              </div>
              {Number.isInteger(winBadgeCount) && <WinBadge code={item.code} count={winBadgeCount} />}
            </li>
          )
        })}
      </ul>
      {!props.hideFooter && (
        <footer className={classes.footer} ref={ref}>
          <Link
            href={isScEnabled && sc ? ROUTES.JACKPOT_PROMOTIONS_SC : ROUTES.JACKPOT_PROMOTIONS}
            className={classes.link}
            data-test={`${JPDataTest}-more-info`}
          >
            {t('jackpots_home_banners.more')}
          </Link>
          {sc && (
            <Button
              disabled={accountJackpotInfoLoading}
              loading={accountJackpotInfoLoading}
              variant={isOptedIn ? 'outlined' : 'contained'}
              className={cx(classes.button, { [classes.optedInButton]: isOptedIn })}
              onClick={isOptedIn ? handleOptOutClick : handleOptInClick}
              data-test={isOptedIn ? 'opt-out-jackpot-button' : 'opt-in-jackpot-button'}
            >
              {isOptedIn ? t('common.opt_out') : t('common.opt_in')}
            </Button>
          )}
        </footer>
      )}
    </div>
  )
}
