import { serviceDependencies } from 'services'
import { arrayFilterNullable } from 'utils/array/filterNullable'

import type { HabaneroJackpot } from './types'

export async function getHabaneroJackpots(currency: 'SC' | 'GC'): Promise<HabaneroJackpot[]> {
  const url = `${process.env.HABANERO_JACKPOTS}?currency=${currency}`

  try {
    const response = await serviceDependencies.fetch(url, null, {
      method: 'get',
    })
    const result = await response.json()

    return arrayFilterNullable(result?.jackpots)
  } catch (error) {
    console.error(`Fetch ${getHabaneroJackpots.name} function error: `, 'URL', url, 'Error', error?.message)

    return []
  }
}
