import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import { useInView } from 'hooks/useInView'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getAccountInvitationsIfRequired } from 'store/modules/accountInvitations/actions'
import { getAccountInvitationsSelector } from 'store/modules/accountInvitations/selectors'
import { getUserAccountIdSelector } from 'store/modules/user/selectors'

import SCInviteFriends from './SCInviteFriends'

export default function ReferFriendSwimlane() {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector(getUserAccountIdSelector)
  const { invitations } = useAppSelector(getAccountInvitationsSelector)
  const { referFriendFeature } = useCountryConfig()
  const { isScEnabled, isLoggedIn } = useAuth()
  const { ref } = useInView({
    triggerOnce: true,
    onChange: function (inView) {
      if (inView) {
        dispatch(getAccountInvitationsIfRequired())
      }
    },
  })

  if (!referFriendFeature.enabled || !isLoggedIn || !isScEnabled) {
    return null
  }

  return <SCInviteFriends accountId={accountId} invitations={invitations} ref={ref} />
}
