import cx from 'classnames'
import { forwardRef } from 'react'

import type { PillProps } from 'components/Pill/Pill.types'
import RainbowBorder from 'components/RainbowBorder'

import classes from './Pill.module.scss'

const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(
  { label, onClick, selected, className, icon = 'top', ...dataAttributes },
  ref
) {
  return (
    <div ref={ref} {...dataAttributes}>
      <RainbowBorder height='38px' width='max-content' borderRadius='200px' enabled={selected}>
        <button className={cx(classes.pillRoot, className, { [classes.selectedPill]: selected })} onClick={onClick}>
          <img className={classes.pillIcon} src={`${process.env.IMG_ORIGIN}/menu/${icon}.svg`} alt='icon' />

          <div className={classes.pillLabel}>{label}</div>
        </button>
      </RainbowBorder>
    </div>
  )
})

export default Pill
