/**
 * This utility function arrayFilterNullable can be very useful in a number of cases, for example:
 *
 *  - Data Cleansing: In applications dealing with large datasets, often the data may contain empty or null values which,
 *      if not handled correctly, could lead to unexpected behavior or errors.
 *      arrayFilterNullable enables removal of such undesired null/undefined values from an array.
 *  - API Responses: Sometimes, arrays received as responses from an API might contain null or undefined values.
 *      Before using such data in the application, it's beneficial to filter out these values to prevent any unexpected issues.
 *  - Type Safety: This function ensures that the returned array is of type T[],
 *      providing type safety when dealing with arrays containing potentially nullable elements.
 *  - Code Readability: The function name arrayFilterNullable clearly indicates its purpose, making the code more readable and understandable.
 *
 *  Example usage:
 *  ```ts
 *  const data: Record<{categories: [] | undefined}> = {
 *    categories: undefined,
 *  }
 *
 *  const filteredData = arrayFilterNullable(data_2?.categories) // Output: []
 *  const plainFilter = data_2?.categories?.filter(Boolean) // Output: [] | undefined
 *  const codeToHaveTypeSafety = (data_2?.categories ?? []).filter(Boolean) // Output: []
 *
 *  const data_2 = [ 'a', null, 'b', undefined, 'c', false, 0, NaN, true ]
 *  const filteredData_2 = arrayFilterNullable(data_2) // Output: [ 'a', 'b', 'c', false, 0, NaN, true ]
 *  const plainFilter_2 = data_2.filter(Boolean) // Output: [ 'a', 'b', 'c', true ]
 *  ```
 */

export function arrayFilterNullable<T>(data?: (T | null | undefined)[] | null): T[] {
  if (data == null || !Array.isArray(data)) {
    return []
  }

  return [...data].filter((el): el is T => el != null)
}
