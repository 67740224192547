import { useCallback, useMemo } from 'react'

import { DROP_SELECTED_CATEGORY } from 'config/customEvents'
import type { Category } from 'containers/Categories/Categories.types'
import useDomEvent from 'hooks/useDomEvent'
import ROUTES from 'temp/routes.json'

import type { HomeContentProps } from '../../HomeContent.types'

import { useCategoryState } from './useCategoryState'

/**
 * `useCategorySelection` is a custom hook that manages the selection state for a list of categories
 * on the home content component. It provides several utilities for category selection such as determining
 * whether an item is selected, executing an action upon clicking a filter, and data regarding the currently selected category.
 *
 * @hook useCategorySelection
 * @param {Object} props - The props object.
 * @param {Category[]} props.categories - An array of available categories.
 *
 * @returns {{
 *   selectedCategoryData: Category | undefined
 *   isItemSelected: (category: Category) => boolean
 *   onClickFilter: (category: Category) => void
 * }} - The hook's output:
 *  - `selectedCategoryData` - The currently selected category data.
 *  - `isItemSelected` - A function that determines whether a category is selected.
 *  - `onClickFilter` - A function that is triggered when a category is clicked.
 *
 * @example
 * const { selectedCategoryData, isItemSelected, onClickFilter } = useCategorySelection({categories});
 */
export const useCategorySelection = ({ categories }: Pick<HomeContentProps, 'categories'>) => {
  const { selectedCategoryCode, toggleCategory } = useCategoryState()

  useDomEvent(DROP_SELECTED_CATEGORY, () => toggleCategory())

  const onClickFilter = useCallback(({ code }: Category) => toggleCategory(code), [toggleCategory])

  const isItemSelected = useCallback(
    ({ code, route }: Category) => {
      const isCategorySelected = selectedCategoryCode == code

      return isCategorySelected && (route === ROUTES.INDEX || isCategorySelected)
    },
    [selectedCategoryCode]
  )

  const selectedCategoryData = useMemo(
    () => categories.find((category) => category.code === selectedCategoryCode),
    [categories, selectedCategoryCode]
  )

  return {
    selectedCategoryData,
    isItemSelected,
    onClickFilter,
  }
}
