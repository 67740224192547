import Skeleton from 'app/components/Skeleton'

import classes from './InviteFriendSkeleton.module.scss'

const InviteFriendInfoSkeleton = () => {
  return (
    <div className={classes.root}>
      <Skeleton length={4} />
    </div>
  )
}

export default InviteFriendInfoSkeleton
