import BannerTile from 'components/BannerTile'
import ROUTES from 'temp/routes.json'

import { generateBannerUrl } from '../utils/generateBannerUrl'

export default function RegisterBanner() {
  const bannerUrl = generateBannerUrl('banners/register-banner-v5/source.png')

  return <BannerTile url={ROUTES.REGISTER} src={bannerUrl} alt='register banner' dataTest='register-gc-banner' />
}
