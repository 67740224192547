import { useState, useEffect, useCallback } from 'react'

import { useAuth } from 'app/context/auth'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getDialogVisibilitySelector, isSignUpSessionSelector } from 'store/modules/dialog/selectors'
import { getOffersRequestSelector } from 'store/modules/sessionDialogs/selectors'
import { getItemFormLocalStorageSelector, setDataToLocalStorage } from 'utils/localStorage'
import { WELCOME_MESSAGE } from 'utils/localStorage'

export const useWelcomeMessage = () => {
  const [isBannerShown, setIsBannerShown] = useState(false)
  const { isLoggedIn } = useAuth()
  const bannerShown = useAppSelector((state) => getItemFormLocalStorageSelector(state, WELCOME_MESSAGE, false))
  const isAnyDialogOpen = useAppSelector(getDialogVisibilitySelector)
  const isSignUpSession = useAppSelector(isSignUpSessionSelector)
  const offersLoaded = useAppSelector(getOffersRequestSelector)
  const dispatch = useAppDispatch()

  const shouldDisplayBanner = isLoggedIn && !bannerShown && !isAnyDialogOpen && !isSignUpSession && offersLoaded

  const displayBanner = useCallback(() => {
    if (shouldDisplayBanner) {
      setIsBannerShown(true)
      dispatch(setDataToLocalStorage(WELCOME_MESSAGE, true, false))
      const hideBannerTimeoutId = setTimeout(() => setIsBannerShown(false), 5000)

      return () => clearTimeout(hideBannerTimeoutId)
    }
  }, [shouldDisplayBanner, dispatch])

  useEffect(() => {
    let displayBannerTimeoutId = null
    if (!isBannerShown) {
      displayBannerTimeoutId = setTimeout(displayBanner, 1000)
    }

    return () => {
      if (displayBannerTimeoutId) {
        clearTimeout(displayBannerTimeoutId)
      }
    }
  }, [displayBanner, isBannerShown])

  return { isBannerShown, setIsBannerShown }
}
