'use client'

import { useTranslation } from '@patrianna/core-components'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'

import Pill from 'components/Pill'
import { getCategories } from 'containers/Categories/utils/getCategories'
import HorizontalScroll from 'containers/HorizontalScroll'
import ROUTES from 'temp/routes.json'

import classes from './Categories.module.scss'
import type { CategoriesProps, Category } from './Categories.types'
import { useAutoScroll } from './hooks/useAutoScroll'
import { useControllers } from './hooks/useControllers'

const hiddenCategoryUrls = [ROUTES.RECENTLY_PLAYED]

export default function Categories({ productLayoutCategories, alignCategoriesOnClick, controllers }: CategoriesProps) {
  const isMobile = useIsMobile()
  const t = useTranslation()

  const categories = getCategories(hiddenCategoryUrls, productLayoutCategories)

  const { getPillRef, autoScrollCategories } = useAutoScroll(
    categories,
    alignCategoriesOnClick,
    controllers?.categoryPredicate
  )

  const actualControllers = useControllers({ controllers, alignCategoriesOnClick })

  return (
    <HorizontalScroll
      showArrows={!isMobile}
      customScroll={autoScrollCategories}
      scrolledItemDataAttr='data-pill'
      className={classes.root}
      classNameContainer={classes.categories}
    >
      {({ scrollRef }) =>
        categories.map((category: Category, index) => {
          const pillRef = getPillRef(index)

          return (
            <Pill
              key={category.route}
              ref={pillRef}
              selected={actualControllers.categoryPredicate(category)}
              label={t(`common.${category.title}`)}
              onClick={() => actualControllers.onCategoryClick(category, pillRef, scrollRef)}
              icon={category.icon}
              data-pill={category.route}
            />
          )
        })
      }
    </HorizontalScroll>
  )
}
