import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import BannerTile from 'components/BannerTile'
import { useAppDispatch } from 'store/hooks'
import { openDialog } from 'store/modules/dialog/actions'

import { generateBannerUrl } from '../utils/generateBannerUrl'

function DailyReward() {
  const dispatch = useAppDispatch()
  const bannerUrl = generateBannerUrl('banners/daily-reward/source.png')
  const { dailyRewardFeature } = useCountryConfig()
  const { isLoggedIn } = useAuth()

  const openModalHandler = () => {
    if (!dailyRewardFeature.enabled && isLoggedIn) {
      return
    }

    dispatch(openDialog({ modalName: 'DAILY_REWARD_PROMO_DIALOG' }))
  }

  return (
    <BannerTile onClick={openModalHandler} src={bannerUrl} alt='For your daily reward' dataTest='daily-reward-banner' />
  )
}

export default DailyReward
