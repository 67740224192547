import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { useEffect, useState } from 'react'

export default function useShareApiAvailability() {
  const [isShareAPIAvailable, setShareAPIAvailability] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (navigator?.share && isMobile) {
      setShareAPIAvailability(true)
    }
  }, [isMobile])

  return { isShareAPIAvailable }
}
