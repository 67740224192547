import cx from 'classnames'

import { Link } from 'components/Link'
import ROUTES from 'temp/routes.json'

import classes from './styles.module.scss'

type Props = {
  isMobile: boolean
}

function BigRegisterBanner({ isMobile }: Props) {
  const bucketUrl = `${process.env.IMG_ORIGIN}/banners/register-banner-v5`

  return (
    <div className={cx(classes.root, { [classes.mobile]: isMobile })}>
      <Link href={ROUTES.REGISTER}>
        {isMobile && (
          <picture>
            <source media='(orientation: landscape)' srcSet={`${bucketUrl}/brb_img_landscape.png`} />
            <img src={`${bucketUrl}/brb_img_mob.png`} className={classes.banner} alt='register banner' />
          </picture>
        )}
        {!isMobile && <img src={`${bucketUrl}/brb_img3.png`} className={classes.banner} alt='register banner' />}
      </Link>
    </div>
  )
}

export default BigRegisterBanner
