import type { TargetBanner } from '@patrianna/shared-patrianna-types/store/BannersModule'
import type { BannerInfo } from '@patrianna/shared-store/banners/store/types'

import { useRouter } from 'app/context/navigation'
import BannerTile from 'components/BannerTile'

type Props = {
  banners: (TargetBanner | BannerInfo)[]
}

export default function TargetBanners({ banners }: Props) {
  const router = useRouter()

  return (
    <>
      {banners.map((i) => (
        <BannerTile
          onClick={() => router.push(i.ctaUrl)}
          src={i.bannerImageUrl}
          alt={i.title}
          key={i.code}
          dataTest={`target-banner-${i.code}`}
        />
      ))}
    </>
  )
}
