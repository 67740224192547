import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import { forwardRef, useMemo } from 'react'
import { VirtuosoGrid } from 'react-virtuoso'
import type { GridComponents } from 'react-virtuoso'

import ProductTile from 'components/ProductTile'
import { arrayFilterNullable } from 'utils/array/filterNullable'

import classes from './ProductGrid.module.scss'

const gridComponents: GridComponents = {
  List: forwardRef(function List({ children, className, ...props }, ref) {
    return (
      <div ref={ref} {...props} className={cx(className, classes.tileContainer)}>
        {children}
      </div>
    )
  }),
  Item: ({ children, ...props }) => <div {...props}>{children}</div>,
}

function ProductGrid({ selectedCategoryData }: { selectedCategoryData: SwimlaneCategory }) {
  const products = useMemo(() => arrayFilterNullable(selectedCategoryData.products), [selectedCategoryData])

  return (
    <section>
      <h3 className={classes.title}>{selectedCategoryData.title}</h3>

      <VirtuosoGrid
        totalCount={products.length}
        components={gridComponents}
        data={products}
        useWindowScroll
        itemContent={(_, game) => (
          <ProductTile key={game.code} game={game} swimlaneStyle={selectedCategoryData.style} />
        )}
      />
    </section>
  )
}

export default ProductGrid
