import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getHabaneroJackpotsAction } from 'store/modules/habaneroJackpots/actions'
import { getHabaneroJackpotsSelector } from 'store/modules/habaneroJackpots/selectors'
import { getPragmaticJackpotsAction } from 'store/modules/pragmaticJackpots/actions'
import { getPragmaticJackpotsSelector } from 'store/modules/pragmaticJackpots/selectors'

const JACKPOT_ENABLED = process.env.JACKPOT_ENABLED

export default function useProviderJackpots() {
  const ref = useRef<Currencies>()
  const dispatch = useAppDispatch()
  const isAppInitialized = useAppSelector(isAppInitializedSelector)
  const sweepstakeEnabled = useAppSelector(sweepstakeEnabledSelector)
  const pragmaticJackpots = useAppSelector(getPragmaticJackpotsSelector)
  const habaneroJackpots = useAppSelector(getHabaneroJackpotsSelector)
  const isJackpotEnabled = JACKPOT_ENABLED && isAppInitialized
  const currency = sweepstakeEnabled ? 'SC' : 'GC'

  useEffect(() => {
    if (isJackpotEnabled && !pragmaticJackpots.length) {
      dispatch(getPragmaticJackpotsAction(currency))
    }

    if (isJackpotEnabled && (!habaneroJackpots.length || currency !== ref.current)) {
      ref.current = currency

      dispatch(getHabaneroJackpotsAction(currency))
    }
  }, [isJackpotEnabled, currency, pragmaticJackpots.length, habaneroJackpots.length])
}
