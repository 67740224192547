import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'

import type { Category } from 'containers/Categories/Categories.types'
import categoriesConfig from 'temp/categories.json'
import ROUTES from 'temp/routes.json'

export const getCategories = (hiddenUrls: string[] = [], productLayoutCategories?: SwimlaneCategory[]) => {
  const availableCategories: Category[] = categoriesConfig.reduce((acc, category) => {
    const isHomeCategory = category.route === ROUTES.INDEX
    const isCategoryHidden = hiddenUrls.includes(category.route)
    const isCategoryInProductLayoutCategories = productLayoutCategories?.some(
      (productLayoutCategory) => productLayoutCategory.code === category?.code
    )

    if ((isCategoryInProductLayoutCategories || isHomeCategory) && !isCategoryHidden) {
      acc.push(category)
    }

    return acc
  }, [])

  return availableCategories
}
