import type { PillRef } from 'containers/Categories/Categories.types'

class CacheAutoScroll {
  private _pathname = ''

  constructor() {
    this.autoScrollCategories = this.autoScrollCategories.bind(this)
  }

  public autoScrollCategories =
    (currentPredicateFn: (ref: PillRef, index: number) => boolean, categories: PillRef[], newPathname: string) =>
    (container: HTMLDivElement) => {
      if (newPathname === this._pathname) {
        return
      }

      this._pathname = newPathname

      let scrollToItemLength: number | null = null

      categories.forEach((ref, index) => {
        if (ref.current != null && currentPredicateFn(ref, index)) {
          scrollToItemLength = ref.current.offsetLeft
        }
      })

      if (scrollToItemLength != null) {
        const leftPadding = 2
        container.scrollTo({ top: 0, left: scrollToItemLength - leftPadding, behavior: 'smooth' })
      }
    }
}

const cacheAutoScroll = new CacheAutoScroll()

export default cacheAutoScroll
