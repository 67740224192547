import { builder } from '@builder.io/react'
import cx from 'classnames'
import { useCallback, useState } from 'react'

import GameRowHeader from 'components/GameRowHeader'
import HorizontalScroll from 'containers/HorizontalScroll'
import useBuilderCMSData from 'hooks/useBuilderCMSData'
import ROUTES from 'temp/routes.json'

import PromoHighlight from './components/PromoHighlight'
import classes from './styles.module.scss'

builder.init(process.env.BUILDER_KEY)

export type PromoHighlightsProps = {
  data: {
    currencyName: string
    imageAlt: string
    imageUrl: string
    promoUrl: string
  }
}

type HighlightsProps = {
  title: string
}

export default function Highlights({ title }: HighlightsProps) {
  const promoHighlights = useBuilderCMSData(process.env.CMS_DATA_MODEL_PROMOTIONS_HIGHLIGHTS)

  const [scrollPage, setScrollPage] = useState(0)
  const [visiblePromotions, setVisiblePromotions] = useState(promoHighlights.length)

  const isShowArrows = promoHighlights.length > 3

  const onScroll = useCallback(
    (container: HTMLDivElement) => {
      if (Math.ceil(container.scrollLeft) + container.clientWidth >= container.scrollWidth) {
        setVisiblePromotions((prevItems) => prevItems + 1)
      }
    },
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
    [visiblePromotions]
  )

  const scrollNext = () => {
    setScrollPage((prevState) => prevState + 1)
  }

  const scrollPrev = () => {
    setScrollPage((prevState) => prevState - 1)
  }

  if (promoHighlights.length) {
    return (
      <div>
        <GameRowHeader
          title={title}
          scrollPrev={scrollPrev}
          scrollNext={scrollNext}
          showArrows={isShowArrows}
          className={classes.title}
          // change when backend change category route (task 1815)
          url={ROUTES.PROMOTIONS}
        />
        <HorizontalScroll
          scrollPage={scrollPage}
          // change when backend change category style (task 1815)
          classNameContainer={`${cx(classes.tilesGrid, classes.tilesContainer)} style-highlights`}
          onScroll={onScroll}
        >
          {promoHighlights.map((el: PromoHighlightsProps, index: number) => (
            <PromoHighlight key={index} promotion={el} />
          ))}
        </HorizontalScroll>
      </div>
    )
  }

  return null
}
