import cx from 'classnames'
import type { ReactElement } from 'react'
import { cloneElement } from 'react'

import classes from './RainbowBorder.module.scss'

type RainbowBorderProps = {
  className?: string
  children: ReactElement
  height: string
  width: string
  borderRadius: string
  enabled?: boolean
}

function RainbowBorder({ className, children, height, width, borderRadius, enabled = true }: RainbowBorderProps) {
  if (!enabled) {
    return children
  }

  return (
    <div className={cx(classes.wrapper, className)} style={{ height, width, borderRadius }}>
      <div className={classes.container}>
        <div className={classes.block} />
      </div>
      {cloneElement(children, { className: cx(children.props.className, classes.childrenWrapper) })}
    </div>
  )
}

export default RainbowBorder
