import type { CategoryCode } from '@patrianna/shared-patrianna-types/services/productCategories'
import type { GameProduct, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { Provider } from '@patrianna/shared-patrianna-types/store/ProviderModule'
import type { GetProductLayoutResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import verifyGameForAllowedChars from '@patrianna/shared-utils/verifyGameForAllowedChars'
import { captureMessage } from '@sentry/nextjs'

import { serviceDependencies } from 'services'
import type { PragmaticJackpot } from 'store/modules/pragmaticJackpots/types'
import ROUTES from 'temp/routes.json'
import { arrayFilterNullable } from 'utils/array/filterNullable'

export async function getAllGames(cookies?: string, headers?: Record<string, string>): Promise<GameProduct[]> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-all-products?brandName=${process.env.BRAND_NAME}`

  let response
  try {
    response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
  } catch (error) {
    // [FYI] log into JS console only, we do not log network errors into Sentry
    console.log('getAllGames Network error', url, error)

    return []
  }
  try {
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`)
    }
    const result = await response.json()

    return arrayFilterNullable(result?.products)
  } catch (error) {
    console.log('getAllGames error', url, error)
    captureMessage(`getAllGames url error ${error?.message}`)

    return []
  }
}

export async function getAllCategories(
  cookies?: string,
  headers?: Record<string, string>
): Promise<SwimlaneCategory[]> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`

  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
    const result = await response.json()

    return arrayFilterNullable(result?.categories)
  } catch (error) {
    console.error(`Error while fetching content from getAllCategories, (${getAllCategories.name})`, error)

    return []
  }
}

export async function getPragmaticJackpots(currency: 'SC' | 'GC'): Promise<PragmaticJackpot[]> {
  try {
    const response = await serviceDependencies.fetch(`${process.env.PRAGMATIC_JACKPOTS}?currency=${currency}`, null, {
      method: 'get',
    })
    const result = await response.json()

    return arrayFilterNullable(result?.jackpots)
  } catch (error) {
    console.error(`Error while fetching content from Pragmatic Jackpots, (${getPragmaticJackpots.name})`, error)

    return []
  }
}

export async function getCategoryByRoute(categoryRoute: string, cookies?: string): Promise<SwimlaneCategory> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`
  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
    const result = await response.json()
    const category = result?.categories.find(
      (i: SwimlaneCategory) => i.route === `${ROUTES.GAMES_SLOTS}/${categoryRoute ? `${categoryRoute}/` : ''}`
    )

    return category
  } catch (error) {
    console.error(`Error while fetching from getCategoryByRoute, (${getCategoryByRoute.name})`, error)

    return null
  }
}

export async function getGameByCode(code: string): Promise<GameProduct> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&code=${code}`

  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get' })
    const game = await response.json()

    verifyGameForAllowedChars(game)

    return game
  } catch (error) {
    console.error(`Error while fetching from getGameByCode, (${getGameByCode.name})`, error)

    return null
  }
}

export async function getGameByRoute(route: string, cookies?: string): Promise<GameProduct> {
  const isServer = !isBrowser()
  const path = isServer ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&route=${route}${
    isServer ? '&showAll=true' : ''
  }`

  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
    const game = await response.json()

    verifyGameForAllowedChars(game)

    return game
  } catch (error) {
    console.error(`Error while fetching from getGameByCode, (${getGameByRoute.name})`, error)

    return null
  }
}

export async function getGamesByCategoryCode(
  placements: {
    productCategoryCode: CategoryCode
    gameId?: string
  }[],
  cookies?: string,
  headers?: Record<string, string>
): Promise<GetProductLayoutResponse> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-category?brandName=${process.env.BRAND_NAME}`
  const body = { placements }

  try {
    const data = await serviceDependencies.fetch(url, body, { cookies }, headers)

    if (!data.ok) {
      return null
    }

    return data.json()
  } catch (error) {
    console.error(`Error while fetching from getGamesByCategoryCode, (${getGamesByCategoryCode.name})`, error)

    return null
  }
}

export async function getProviderURL({ apiUrl, body }: any) {
  const fetchPragmaticData = async () => {
    return await serviceDependencies.fetch(apiUrl as string, body, { method: 'POST' })
  }

  try {
    const response = await fetchPragmaticData()
    const { gameUrl } = await response.json()

    if (gameUrl) {
      return gameUrl
    }
  } catch (error) {
    console.log({ error })
  }

  return ''
}

export async function getAllProviders(
  cookies?: string,
  headers?: Record<string, string>
): Promise<{ providers: Provider[] }> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/brands/${process.env.BRAND_NAME}/suppliers`
  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
    const result = await response.json()

    return { providers: arrayFilterNullable(result?.suppliers) }
  } catch (error) {
    console.error(`Fetch ${getAllProviders.name} function error: `, 'URL', url, 'Error', error?.message)

    return { providers: [] }
  }
}

export async function getProviderByCode(
  code: string,
  cookies?: string,
  headers?: Record<string, string>
): Promise<{ provider: Provider }> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/brands/${process.env.BRAND_NAME}/suppliers/${code}`
  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)

    if (!response.ok) {
      return null
    }

    const { supplier } = await response.json()

    return { provider: supplier }
  } catch (error) {
    console.error(`Fetch ${getProviderByCode.name} function error: `, 'URL', url, 'Error', error?.message)

    return null
  }
}
