import { matchMediaFallback } from '@patrianna/shared-utils/helpers'
import { useEffect, useState } from 'react'

export default function useIsLandscape() {
  const [isLandscape, setIsLandscape] = useState(false)

  useEffect(() => {
    const orientation = matchMediaFallback('(orientation: landscape)')

    const changeLandscape = (e: MediaQueryListEvent) => setIsLandscape(e.matches)

    setIsLandscape(orientation.matches)

    orientation.addEventListener(changeLandscape)

    return () => orientation.removeEventListener(changeLandscape)
  }, [])

  return isLandscape
}
