import { IconButton } from '@patrianna/core-components'
import { useTranslation } from '@patrianna/core-components'
import { Close } from 'mdi-material-ui'

import { useWelcomeMessage } from 'containers/WelcomeMessage/useWelcomeMessage'
import { useAppSelector } from 'store/hooks'
import { getBasePrefillDataFromJumioSoftKyc } from 'store/modules/user/selectors'

import classes from './WelcomeMessage.module.scss'

export default function WelcomeMessage() {
  const t = useTranslation()
  const { isBannerShown, setIsBannerShown } = useWelcomeMessage()
  const profileData = useAppSelector(getBasePrefillDataFromJumioSoftKyc)

  if (!isBannerShown) {
    return null
  }

  const handleCloseBanner = () => {
    setIsBannerShown(false)
  }

  return (
    <section className={classes.root}>
      <div className={classes.text}>
        {profileData.firstName}, {t('common.welcome_back')} {t('common.brand_name')}!
      </div>
      <IconButton aria-label={t('common.close')} className={classes.close} onClick={handleCloseBanner}>
        <Close className={classes.icon} />
      </IconButton>
    </section>
  )
}
