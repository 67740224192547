import { useTranslation } from '@patrianna/core-components'
import type { JackpotTotalsCode } from '@patrianna/shared-patrianna-types/store/JackpotsModule'
import cx from 'classnames'
import React from 'react'

import classes from './styles.module.scss'

type Props = {
  code: JackpotTotalsCode
  count: number
}

const WinBadge = ({ code, count }: Props) => {
  const t = useTranslation()

  const countLabel = {
    1: t('jackpots_home_banners.win_badge_once'),
    2: t('jackpots_home_banners.win_badge_twice'),
  }[count]

  return (
    <div className={classes.winContainer}>
      <img
        src={`${process.env.IMG_ORIGIN}/jackpots/win-${code}.png`}
        alt='win badge'
        className={classes.winImage}
        width={92}
        height={92}
      />
      <div className={classes.winContent}>
        <p className={classes.winText}>{t('jackpots_home_banners.win_badge_label')}</p>
        <p className={cx(classes.winCount, { [classes.winCountVerbal]: countLabel })}>{countLabel || `${count} X`}</p>
      </div>
    </div>
  )
}

export default WinBadge
