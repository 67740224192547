import BannerTile from 'components/BannerTile'

import { generateBannerUrl } from '../utils/generateBannerUrl'

type Props = {
  imgKey: 'instagram' | 'facebook'
  link: string
}

function SocialBanner(props: Props) {
  const { link, imgKey } = props
  const bannerUrl = generateBannerUrl(`banners/${imgKey}.png`)

  return <BannerTile url={link} openInNewTab src={bannerUrl} alt={`visit ${imgKey}`} dataTest={`${imgKey}-banner`} />
}

export default SocialBanner
