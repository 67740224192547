import { B2Carousel } from '@patrianna/core-components'
import cx from 'classnames'
import type { ReactNode } from 'react'

import type { HorizontalScrollProps } from 'containers/HorizontalScroll/HorizontalScroll.types'
import { useAppSelector } from 'store/hooks'
import { getDialogVisibilitySelector } from 'store/modules/dialog/selectors'

import classes from './BannerCollection.module.scss'

type Props = {
  children: ReactNode
  enableBackdrop?: boolean
  showPagination?: boolean
  showArrows?: boolean
  className?: string
  scrollClassName?: string
  autoScrollTimeInterval?: number
} & HorizontalScrollProps

export default function BannerCollection(props: Props) {
  const dialogVisible = useAppSelector(getDialogVisibilitySelector)

  return (
    <B2Carousel
      autoScrollInterval={props.autoScrollTimeInterval}
      showArrows={props.showArrows}
      autoScroll={!dialogVisible}
      showPagination={props.showPagination}
      classNameContainer={cx(classes.root, props.scrollClassName)}
      scrolledItemDataAttr='data-banner'
      className={cx(classes.container, props.className, props.enableBackdrop && classes.backdrop)}
      {...props}
    >
      {props.children}
    </B2Carousel>
  )
}
