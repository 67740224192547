import { nextTick } from '@patrianna/shared-utils'
import { usePathname } from 'next/navigation'
import type { MutableRefObject } from 'react'

import { useRouter } from 'app/context/navigation'

import type { CategoriesProps, Category, PillRef } from '../Categories.types'
import { scrollToPill } from '../utils/scrollToPill'

/**
 * A custom hook for controlling category state and actions.
 *
 * @hook useControllers
 * @param {Pick<CategoriesProps, 'controllers' | 'alignCategoriesOnClick'>} props - An object that contains the `controllers` and `alignCategoriesOnClick` properties.
 *   @property {Array<Category>} props.controllers - Defines the array of category controllers.
 *   @property {boolean} props.alignCategoriesOnClick - Specifies if categories should align on click.
 *
 * @returns {{
 *   onCategoryClick: (category: Category, pillRef?: PillRef, scrollRef?: MutableRefObject<HTMLDivElement>) => void,
 *   categoryPredicate: (category: Category) => boolean
 * }} An object that contains the following methods and properties:
 *   - `onCategoryClick` - A function that is triggered when a category is clicked. It takes the selected category and optional pill and scroll references.
 *   - `categoryPredicate` - A predicate function that verifies if the current route is the same as the category route. This function takes the category object as argument.
 *
 * This hook leverages the useRouter and usePathname hooks from Next.js to handle routing and navigation among categories. It also uses the scrollToPill utility for scroll actions.
 */
export const useControllers = ({
  controllers,
  alignCategoriesOnClick,
}: Pick<CategoriesProps, 'controllers' | 'alignCategoriesOnClick'>) => {
  const { push } = useRouter()
  const pathname = usePathname()

  const baseControllers = {
    categoryPredicate: ({ route }: Category): boolean => route === pathname,
    onCategoryClick: ({ route }: Category) => nextTick(() => push(route)),
    ...controllers,
  }

  if (alignCategoriesOnClick) {
    return {
      ...baseControllers,
      onCategoryClick: (category: Category, pillRef?: PillRef, scrollRef?: MutableRefObject<HTMLDivElement>) => {
        baseControllers.onCategoryClick(category)
        scrollToPill(pillRef, scrollRef)
      },
    }
  }

  return baseControllers
}
