import { handleBuilderRequest } from '@patrianna/shared-utils/builder/handleBuilderRequest'
import { unstable_cache } from 'next/cache'
import { useCallback, useEffect, useState } from 'react'

import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import { getBuilderFilteredData } from 'app/utils/getBuilderFilteredData'

export default function useBuilderCMSData(builderModel: string) {
  const [builderData, setBuilderData] = useState([])
  const { isScEnabled } = useAuth()
  const enabledCurrency = isScEnabled ? 'SC' : 'GC'
  const { countryCode } = useCountryConfig()

  const fetchData = useCallback(async () => {
    try {
      const items = await handleBuilderRequest({
        enableCache: false,
        model: builderModel,
        queryParams: {
          url: '/',
          prerender: false,
          cacheSeconds: 0,
        },
        cache: unstable_cache,
      })

      setBuilderData(getBuilderFilteredData(items, countryCode, enabledCurrency))
    } catch (error) {
      console.error(`Error while fetching from Builder.io, (${useBuilderCMSData.name})`, error)
    }
  }, [builderModel, countryCode, enabledCurrency])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return builderData
}
