import { useTranslation } from '@patrianna/core-components'
import { useCopyClipboard } from '@patrianna/shared-hooks'
import cx from 'classnames'

import Button from 'components/Button'
import { SharingNativeMobile } from 'components/SharingNativeMobile'
import { trackEvent } from 'config/analytic'
import useShareApiAvailability from 'hooks/useShareApiAvailability'

import classes from './styles.module.scss'

type Props = {
  inputText: string
  copyCustomEvent?: string
  shareCustomEvent?: string
  className?: string
  buttonsClassName?: string
  shareNative?: boolean
  hideInputText?: boolean
  customShareTextMobile?: string
}

export default function CopyToClipboardInput(props: Props) {
  const {
    className,
    buttonsClassName,
    inputText,
    shareNative,
    customShareTextMobile,
    copyCustomEvent,
    hideInputText,
    shareCustomEvent,
  } = props
  const [isCopied, setClipboard] = useCopyClipboard(inputText)
  const { isShareAPIAvailable } = useShareApiAvailability()
  const t = useTranslation()

  //B2SPIN-4907 for mobile <Copy> button should be hidden only <Share> button should be shown
  const isCopyButtonHidden = isShareAPIAvailable && !!customShareTextMobile

  const handleClick = () => {
    if (!isCopied) {
      trackEvent(copyCustomEvent || 'clicked_on_referfirend_copylink')
    }
    setClipboard()
  }

  return (
    <div className={cx(classes.root, className)}>
      {!hideInputText && <div className={classes.linkHolder}>{inputText}</div>}

      <div className={cx(classes.buttonsContainer, buttonsClassName)}>
        {!isCopyButtonHidden && (
          <Button
            className={classes.copyButton}
            onClick={handleClick}
            variant='outlined'
            data-test='invite-friends-copy-link-button'
          >
            {isCopied ? t('common.copied') : t('common.copy_link')}
          </Button>
        )}

        {shareNative && (
          <SharingNativeMobile
            className={classes.shareButton}
            text={customShareTextMobile || `${t('common.share_link_custom_text')}: ${inputText}`}
            customEvent={shareCustomEvent}
          />
        )}
      </div>
    </div>
  )
}
